import React from "react"

import { LayoutProvider } from "gatsby-theme-photography-portfolio/src/context/LayoutContext"
import { GlobalStyle } from "../utils/styles"

const Layout = ({ children }) => (
  <LayoutProvider>
    <GlobalStyle />
    {children}
  </LayoutProvider>
)

export default Layout
